import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommenService } from './common.service';
import { map, catchError, finalize } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ErrorHandlerService } from './error-handler.service';
import { articles_data_domain } from './app.config';
@Injectable({
  providedIn: 'root'
})
export class MainService {

  constructor( private _http: HttpClient,
    private _commonService: CommenService,
    private _errHandler: ErrorHandlerService) { }


  getData(paramsData: any): Observable<any> {
    const headers = this._commonService.getHeaders();
    return this._http
      .get<any>(
        `${articles_data_domain + paramsData}`,
        {
          headers,
        }
      )
      .pipe(
        map((res) => res),
        catchError((error) => this._errHandler.handleError(error)),
        finalize(() => { })
      );
  }


  getArticlesDetails(paramsData: string){
    const headers = this._commonService.getHeaders();
    return this._http
      .get<any>(
        `${articles_data_domain}${paramsData}/${paramsData}.json`,
        {
          headers,
        }
      )
      .pipe(
        map((res) => res),
        catchError((error) => this._errHandler.handleError(error)),
        finalize(() => { })
      );
  }
}
