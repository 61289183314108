import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(private snackBar: MatSnackBar) { }

  openSnackBar(message: string,  customClass: string, action: string = '', config?: MatSnackBarConfig) {
    const defaultConfig: MatSnackBarConfig = {
      duration: 3000, // Auto close after 3 seconds
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: customClass
    };
    
    this.snackBar.open(message, action, { ...defaultConfig, ...config });
  }
}