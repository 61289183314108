import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { WINDOW } from './custom-window-token';
import { inject } from '@angular/core';
import {snackbar_type, status_code} from './common.service'
import { SnackBarService } from './snack-bar.service';
@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {
  private readonly window = inject(WINDOW);
  constructor( private _snackbar: SnackBarService) { }
handleError(errRes: HttpErrorResponse) {
    if (errRes instanceof HttpErrorResponse) {
      // Server or connection error happened
      let status = errRes.status;
      const error = errRes.error; // can be null or any
      let errMsg = errRes.message; // top level message
      if (!this.window.navigator.onLine) {
        // Handle offline error
        errMsg = 'No Internet Connection';
        this._snackbar.openSnackBar(errMsg, snackbar_type.warning);

      } else {
        // Handle Http Error (error.status === 403, 404...
        // finding detail error message
        if (error && error.status === 'error') {
          errMsg = error.message;
          status = error.code;
        } else if (error && typeof error === 'string') {
          errMsg = error; // html returned
        }
        if (status === status_code.unauthorized) {
          // show unauthorized alert OR session expired alert
          this._snackbar.openSnackBar(errMsg, snackbar_type.warning);

        } else if (
          status === status_code.bad_request ||
          (status >= 402 && status <= 499)
        ) {
          // let component handle bad request error using throw error
          // OR
          // let component handle all 400 series errors using throw error
        } else if (
          status === status_code.server_error ||
          status === status_code.not_found
        ) {
          // navigate to custom pages
          // const state: NavigationExtras = {
          //   state: new String(JSON.stringify(errRes)),
          // };
          // this._nav.navigate(["/errors/error"], state);

          // OR show error alert
          this._snackbar.openSnackBar(errMsg, snackbar_type.danger);

        } else {
          // any other type errors
          errMsg = 'Server is not responding!';
          this._snackbar.openSnackBar(errMsg, snackbar_type.info);

        }
      }
    } else {
      // Handle Client Error (Angular Error, ReferenceError...)
    }

    return throwError(errRes);
  }
}
