import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class CommenService {
//  themeSelected: string = 'first-theme';
//  themeSelected: string = 'second-theme';
  themeSelected: string = 'third-theme';
  latestRecords :any = [];
  selectedRecod : any = {};
  selectedTitle: string = '';

  constructor(@Inject(DOCUMENT) private document: Document,private _http: HttpClient,) {}

  loadStyle(styleUrl: string): void {
    if (isPlatformBrowser(this.document)) { // Check for browser environment
      const link = this.document.createElement('link');
      link.rel = 'stylesheet';
      link.href = styleUrl;
      this.document.head.appendChild(link);
    }
  }

  removeStyle(styleUrl: string): void {
    if (isPlatformBrowser(this.document)) { // Check for browser environment
      const links = this.document.getElementsByTagName('link');
      for (let i = 0; i < links.length; i++) {
        if (links[i].href.includes(styleUrl)) {
          links[i].parentNode?.removeChild(links[i]);
        }
      }
    }
  }



  getHeaders() {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('Access-Control-Allow-Origin', '*');
    // const token = this.getLoggedInUser().token;
    // headers = headers.append('x-access-token', token);
    return headers;
  }


}

export enum status_code {
  informational = 501,
  ok = 200,
  created = 201,
  bad_request = 400,
  unauthorized = 401,
  not_found = 404,
  server_error = 500,
}
export enum snackbar_type { // corresponding css in styles.scss
  info = 'snack_info',
  danger = 'snack_danger',
  success = 'snack_success',
  warning = 'snack_warning',
  default = 'snack_default',
}
