import { Routes } from '@angular/router';
import { LandingPageComponent } from './TopViralDose/landing-page/landing-page.component';

export const routes: Routes = [
    
    { path: '', pathMatch:'full', redirectTo: 'home' },
    { path: 'home', loadComponent: () => import('./main-page/main-page.component').then(m => m.MainPageComponent) },
    { path: 'contact-us', loadComponent: () => import('./contact-form/contact-form.component').then(m => m.ContactFormComponent) },
    { path: 'category-celeb', loadComponent: () => import('./category-celebrities/category-celebrities.component').then(m => m.CategoryCelebritiesComponent) },
    { path: 'category-entertain', loadComponent: () => import('./category-entertain/category-entertain.component').then(m => m.CategoryEntertainComponent) },
    { path: 'category-lifestyle', loadComponent: () => import('./category-lifestyle/category-lifestyle.component').then(m => m.CategoryLifestyleComponent) },
    { path: 'not-found', loadComponent: () => import('./error404/error404.component').then(m => m.Error404Component) },
    { path: 'privacy-policy', loadComponent: () => import('./privacy-policy/privacy-policy.component').then(m => m.PrivacyPolicyComponent) },
    { path: 'terms-of-use', loadComponent: () => import('./terms-of-use/terms-of-use.component').then(m => m.TermsOfUseComponent) },

    
    // { path: 'test/:article', loadComponent: () => import('./BozobaTheme/bozoba-landing-page/bozoba-landing-page.component').then(m => m.BozobaLandingPageComponent) },
    // { path: 'test2', loadComponent: () => import('./header/header.component').then(m => m.HeaderComponent) },

    { path: ':article', loadComponent: () => import('./landing-page/landing-page.component').then(m => m.LandingPageComponent) },
];
