import { ApplicationConfig, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import {
  provideClientHydration,
  withHttpTransferCacheOptions,
} from '@angular/platform-browser';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideClientHydration(
      withHttpTransferCacheOptions({
        includePostRequests: true,
      })
    ),
    provideHttpClient(withFetch()),
    provideAnimationsAsync(),
  ],
};
export const articles_data_domain: string = "https://dse012q2h7evu.cloudfront.net/";
export const articles_list: string[] = [
  'living-legends-from-past-golden-eras',
  'some-exceptional-striking-moments-dianas-life-captured-by-camera',
  'very-last-heart-wrenching-messages-from-athletes-before-their-deaths',
  'worlds-most-enchanting-ladies-forever',
  'worlds-astonishingly-large-athletes-you-wont-believe-their-size-until-you-see-them-next-to-others',
  'most-absurd-bridal-outfits',
  'these-celebrities-look-unbelievable-after-their-miraculous-weight-loss-alterations',
  'fascinating-facts-famous-cheer-leading-squads-mens-participation-in-these-squads',
  'stunning-mary-kate-ashley-olsen-have-look-on-evolution-their-style-over-years',
  'tiger-woods-elin-nordegren-finally-speak-up-about-his-scandal-their-failed-marriage',
  'these-celebrities-their-children-look-so-incredibly-similar',
  'people-teased-him-for-marrying-her-six-years-later-her-makeover-stunned-everyone',
  'struggling-with-weight-loss-here-are-50-amazing-celebrity-weight-loss-transformations-to-give-you-motivation',
  'celebrity-couple-who-were-born-to-love-each-other-forever',
  'these-harry-potter-stars-are-getting-older-where-are-they-now',
  'trick-treat-here-are-best-halloween-costumes-that-our-favorite-celebrities-have-rocked',
  'golden-age-stars-who-are-still-alive-doing-great-at-retirement',
  'must-see-camping-photos-thatll-make-your-day',
  'girl-23-marries-homeless-man---guests-at-wedding-laugh-until-he-reveals-his-wedding-gift',
  '15-celebrities-who-were-blacklisted-from-hollywood',
  'hairdresser-combing-teens-hair-gets-surprise-her-life',
  '9-most-expensive-movie-costumes-ever-made',
  'most-incredible-weight-loss-transformations-techniques-hollywood-celebrities-',
  '10-movies-where-actresses-had-to-wear-so-revealing-costumes-they-even-made-us-blush',
  'fall-from-grace-nfl-legends-who-blew-their-fortunes',
  'amazing-car-hacks-everyone-needs-to-start-doing-now',
  '35-foods-we-should-never-refrigerate',
  'extravagance-redefined-unmasking-dubais-unprecedented-luxury-lifestyle',
  'untamed-tales-unveiling-enthralling-lives-fearless-country-girls',
  'craziest-things-people-did-to-go-viral-on-internet',
  'female-trouble-rib-cracking-photos-women-facing-car-woes',
  'from-hillarious-to-embarrassing-45-iconic-news-bloopers-became-viral-sensations',
  '2023-met-gala-celebrities-who-nailed-theme-dazzled-us-all',
  'set-sail-for-laughs-these-40-rib-tickling-boating-moments-will-leave-you-hooked',
  'happily-ever-laughter-capturing-most-hilarious-wedding-moments-in-photos',
  'spot-difference-celebrities-who-could-pass-as-long-lost-twins',
  'beach-bloopers-hilarious-bizarre-moments-captured-on-camera',
  'ugly-side-fame-famous-feet-that-have-left-us-unimpressed',
  'funniest-most-interesting-sports-moments-ever-caught-on-cam',
  'comedy-in-classroom-wacky-teachers-who-raised-humor-bar',
  'ms-universe-world-cup-find-out-who-among-these-fans-is-fairest-them-all',
  'mind-blowing-bodypaint-masterpieces-thatll-leave-you-awestruck-spellbound',
  '30-mind-bending-photos-that-had-us-looking-twice',
  'cringeworthy-hotel-room-photos-that-will-leave-you-questioning-your-perception-sanity',
  'no-need-to-ask-here-are-30-sure-fire-ways-identifying-tourist-from-america',
  'these-pictures-will-make-you-think-twice-about-drinking-too-much',
  'unforgettable-figures-history-lives-legacies-remarkable-individuals',
]; // List of articles
